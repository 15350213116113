import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import BigBookNowButton from "../buttons/BookNowButton";
import "./navigation.css";

function Navigation() {
  const location = useLocation();
  return (
    <React.Fragment>
      <div className="first-banner">
        <div className="first-banner-content">
          {location.pathname !== "/asdad" && <h1>Welcome to</h1>}
          <span className="highlight">Juan's Car Wash & Detail</span>
        </div>
      </div>
      <Navbar bg="light" className="navbar-with-border">
        <Container>
          <Nav className="mx-auto align-items-center nav">
            <NavLink
              to="/"
              className={'nav-link'}
            >
              Home
            </NavLink>
            <NavLink
              to="/pricing"
              className={'nav-link'}
            >
              Pricing
            </NavLink>
            <NavLink
              to="/testimonials"
              className={'nav-link'}
            >
              Testimonials
            </NavLink>
            <BigBookNowButton />
          </Nav>
        </Container>
      </Navbar>
    </React.Fragment>
  );
}

export default Navigation;

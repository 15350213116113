import React, { useState } from "react";
import { FaArrowAltCircleRight } from "react-icons/fa";
import "./booknowbutton.css";
import BookingModal from "../modals/BookingModal";
import "./booknowbutton.css";

function BookNowButton() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <button
        className="btn btn-lg book-now-btn arrow-button secondary-text"
        onClick={handleShow}
      >
        Book Now!{" "}
        <FaArrowAltCircleRight className="arrow-margin" size="1.5em" />
      </button>
      <BookingModal show={show} handleClose={handleClose} />
    </React.Fragment>
  );
}

export default BookNowButton;

import React from "react";
import Navigation from "../navigation/Navigation";
import "./pricingpage.css";

function PricingPage() {
  return (
    <React.Fragment>
      <Navigation></Navigation>
      <section className="pricing-content secondary-text">
        <div className="container">
          <br />
          <div className="row text-center ">
            <div className="col-lg-6 col-sm-6 col-xs-12 margin-bottom">
              <div className="pricing_design">
                <div className="single-pricing">
                  <div className="price-head">
                    <h2>Exterior Pricing</h2>
                  </div>
                  <ul className="text-center">
                    <li>
                      <b>$5 - Basic Wash</b>
                    </li>
                    <li>
                      <b>$8 - Hand Wash</b>
                    </li>
                    <li>
                      <b>$2 - Wheel Wash/Shine</b>
                    </li>
                    <br />
                    <h3>Add-Ons</h3>
                    <li>
                      <b>$ 5 - Better Soap (Wax & Ceramic Infused)</b>
                    </li>
                    <li>
                      <b>$7 - Deep Wash Rims & Tires</b>
                    </li>
                    <li>
                      <b>(Automatic $5 fee for bigger vehicle)</b>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-xs-12 margin-bottom">
              <div className="pricing_design">
                <div className="single-pricing">
                  <div className="price-head">
                    <h2>Interior Pricing</h2>
                  </div>
                  <ul>
                    <li>
                      <b>$10 - Wipe Interior Plastics</b>
                    </li>
                    <li>
                      <b>$5-10 - Wipe Interior Windows</b>
                    </li>
                    <li>
                      <b>$5 - Wipe Leather Seats</b>
                    </li>
                    <li>
                      <b>$40-80 - Clean Interior Carpets</b>
                    </li>
                    <li>
                      <b>$10-15 - Clean Carpet Mats</b>
                    </li>
                    <b>
                      <li>$5-10 - Clean Plastic Mats</li>
                    </b>
                    <br />
                    <h3>Add-Ons</h3>
                    <li>
                      <b>$10-25 - Deep Clean Interior Compartments</b>
                    </li>
                    <li>
                      <b>$5-10 - Deep Clean Leather Seats</b>
                    </li>
                    <li>
                      <b>$5-30 - Clean Hard Carpet Stains</b>
                    </li>
                    <li>
                      <b>
                        $20-45 - Condition/Protectant for Leather Upholstery
                      </b>
                    </li>
                    <li>
                      <b>$10-25 - Deep Clean Cloth Seats</b>
                    </li>
                    <li>
                      <b>(Automatic $5 fee for bigger vehicle)</b>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default PricingPage;

import React, { useRef } from "react";
import Slider from "react-slick";
import "./carousel.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const before = "Before";
const after = "After";

const dajocodeImgaeTag = (key) => {
  const prefix = "https://dajocode-cloud-storage-api.com/api/read?key=juans-car-wash-and-detail/";
  return `${prefix}${key}`;
}

const images = [
  { src: dajocodeImgaeTag('honda/1/66dc254bbb77495ba2bc0540352696bd'), alt: "Before Honda 1", label: before },
  { src: dajocodeImgaeTag('honda/1/cf8ab47f580f42939caead3360d7364e'), alt: "After Honda 1", label: after },
  { src: dajocodeImgaeTag('honda/2/a25a576edc19418893f25440c74da52e'), alt: "Before Honda 2", label: before },
  { src: dajocodeImgaeTag('honda/2/749b7b682ad54125b0c2da99fa1d9995'), alt: "After Honda2", label: after },
  { src: dajocodeImgaeTag('honda/3/eb7bb2fb390e43a79d5a5e0cbe0dd7b6'), alt: "Before Honda 3", label: before },
  { src: dajocodeImgaeTag('honda/3/06342e4528494c2d8b855f68c417e7dc'), alt: "After Honda 3", label: after },
  { src: dajocodeImgaeTag('honda/4/9cfe67356524462c934f67fbca5bb95c'), alt: "Before Honda 4", label: before },
  { src: dajocodeImgaeTag('honda/4/2e9974bfd5b54bd9998f6ea0e703a28f'), alt: "After Honda 4", label: after },
  { src: dajocodeImgaeTag('infinity/1/d0c0040f318f4089826467371684dbb5'), alt: "Before Infiniti 1", label: before },
  { src: dajocodeImgaeTag('infinity/1/4f39da8ce1ad4baa8c33ae54040e5524'), alt: "After Infiniti 1", label: after },
  { src: dajocodeImgaeTag('infinity/2/b8e48423f76a4db790fb3582ae51a342'), alt: "Before Infiniti 2", label: before },
  { src: dajocodeImgaeTag('infinity/2/9f8b0c05ce94412f9fadb50ecfff99e9'), alt: "After Infiniti 2", label: after },
  { src: dajocodeImgaeTag('infinity/3/eca86960d8d54df482073454e0223c8d'), alt: "Before Infiniti 3", label: before },
  { src: dajocodeImgaeTag('infinity/3/b898cdac61644b1fafc7ec703462f3ca'), alt: "After Infiniti 3", label: after },
];

const Carousel = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 400,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className="max-width-container">
      <div className="carousel-wrapper">
        <FaArrowLeft onClick={handlePrev} className="arrow-left" size="2em" />
        <div className="carousel-container col-9 slider-wrapper slick-slider">
          <Slider {...settings} ref={sliderRef}>
            {images.map((image, index) => (
              <div key={index} className="text-center">
                <h4 className="secondary-text-carousel" style={{fontFamily:'monospace', fontWeight:'normal'}}>{image.label}</h4>
                <img
                  className={`carousel-images ${image.label === "Before" ? 'left' : 'right'}`}
                  src={image.src}
                  alt={image.alt}
                />
              </div>
            ))}
          </Slider>
        </div>
        <FaArrowRight onClick={handleNext} className="arrow-right" size="2em" />
      </div>
    </div>
  );
};

export default Carousel;

import React from "react";
import Navigation from "../navigation/Navigation";
import "./testimonialspage.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { FaCarAlt } from "react-icons/fa";

function TestimonialsPage() {
  return (
    <React.Fragment>
      <Navigation />
      <br />
      <section className="title-content ">
        <div className="container">
          <div className="section-title text-center">
            <h4 className="secondary-text">
              Don't just take our word for it, hear what our customers have to
              say.
            </h4>
          </div>
        </div>
      </section>{" "}
      <div className="container-fluid">
        <Row>
          <Col
            className="card-margin d-flex justify-content-center"
            xs={12}
            md={6}
            lg={4}
            xl={3}
          >
            <Card style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title className="secondary-text">
                  <b>Sandra V's Experience</b>
                </Card.Title>
                <Card.Text className="secondary-text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum
                  perspiciatis, debitis aliquid nisi et necessitatibus ut animi,
                  beatae vel sit amet non corrupti vitae quam voluptas, odio
                  voluptatum accusantium nulla.
                </Card.Text>
                <div>
                  <b>
                    <FaCarAlt size="1.5em" /> 2023 Lexus
                  </b>
                </div>
                <br />

                <div>
                  <b>-Mar 2023</b>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            className="card-margin d-flex justify-content-center"
            xs={12}
            md={6}
            lg={4}
            xl={3}
          >
            <Card style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title className="secondary-text">
                  <b>Sandra V's Experience</b>
                </Card.Title>
                <Card.Text className="secondary-text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum
                  perspiciatis, debitis aliquid nisi et necessitatibus ut animi,
                  beatae vel sit amet non corrupti vitae quam voluptas, odio
                  voluptatum accusantium nulla.
                </Card.Text>
                <div>
                  <b>
                    <FaCarAlt size="1.5em" /> 2023 Lexus
                  </b>
                </div>
                <br />

                <div>
                  <b>-Mar 2023</b>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            className="card-margin d-flex justify-content-center"
            xs={12}
            md={6}
            lg={4}
            xl={3}
          >
            <Card style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title className="secondary-text">
                  <b>Sandra V's Experience</b>
                </Card.Title>
                <Card.Text className="secondary-text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum
                  perspiciatis, debitis aliquid nisi et necessitatibus ut animi,
                  beatae vel sit amet non corrupti vitae quam voluptas, odio
                  voluptatum accusantium nulla.
                </Card.Text>
                <div>
                  <b>
                    <FaCarAlt size="1.5em" /> 2023 Lexus
                  </b>
                </div>
                <br />

                <div>
                  <b>-Mar 2023</b>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            className="card-margin d-flex justify-content-center"
            xs={12}
            md={6}
            lg={4}
            xl={3}
          >
            <Card style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title className="secondary-text">
                  <b>Sandra V's Experience</b>
                </Card.Title>
                <Card.Text className="secondary-text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum
                  perspiciatis, debitis aliquid nisi et necessitatibus ut animi,
                  beatae vel sit amet non corrupti vitae quam voluptas, odio
                  voluptatum accusantium nulla.
                </Card.Text>
                <div>
                  <b>
                    <FaCarAlt size="1.5em" /> 2023 Lexus
                  </b>
                </div>
                <br />

                <div>
                  <b>-Mar 2023</b>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
export default TestimonialsPage;

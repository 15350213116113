import React from "react";
import Navigation from "../navigation/Navigation";
import Footer from "../footer/Footer";
import Carousel from "../carousel/Carousel";
import "./homepage.css";

function HomePage() {
  return (
    <React.Fragment>
      <Navigation></Navigation>
      <br />
      <br />
      <div className="hero-container" >
          <img src={"https://dajocode-cloud-storage-api.com/api/read?key=juans-car-wash-and-detail/1a8e73c77f30473296926e729983a03e"} alt="Car Wash Logo" className="logo1" />
        <h1 className="message">
          I PROMISE TO GIVE A PERSONALIZED EXPERIENCE TO EACH AND EVERY CUSTOMER
          WHICH IS WHY MY SERVICES ARE SO SPECIFIC AND ALSO WHY EXACT PRIGING IS
          ONLY AVAILABLE IN PERSON. I DO THIS TO ENSURE THAT YOU'RE GETTING WHAT
          YOU WANT FOR THE PRICE YOU WANT
        </h1>
      </div>
      <br />
      <div className="second-banner">
        <div className="second-banner-content">
          <h1>See Why Our Customers Love Us!</h1>
          <h1>Car Wash After Car Wash, We Deliver Results.</h1>
        </div>
      </div>
      <br />
      <Carousel />
      <br />
      <br />
      <Footer></Footer>
    </React.Fragment>
  );
}

export default HomePage;

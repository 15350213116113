import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaPhone } from "react-icons/fa";
import "./footer.css";

function Footer() {
  return (
    <React.Fragment>
      <footer style={{ backgroundColor: "#007bff", padding: "20px", color:"white", marginTop:"20px" }}>
        <Container fluid>
          <Row className="d-flex flex-column flex-md-row justify-content-md-center">
            <Col
              xs={12}
              md={8}
              className="d-flex flex-column flex-md-row justify-content-between"
            >
              <Col xs={12} md={6} className="mb-2 mb-md-0">
                <h4 className="text-center secondary-text">About Us</h4>
                <p className="secondary-text text-center">
                  I'm all about giving you a tailored experience that's just for
                  you. That's why I get into the specifics and only offer exact
                  pricing when we chat in person. Trust me, it's all about
                  making sure you're getting the most bang for your buck.
                </p>
              </Col>
              <Col xs={12} md={6} className="mb-2 mb-md-0">
                <h4 className="text-center secondary-text">Contact</h4>
                <address className="text-center secondary-text">
                  <strong>Juan's Car Washing & Detail</strong>
                  <br />
                  <strong>
                    <FaPhone className="phone-icon" /> 213-732-9769
                  </strong>
                </address>
              </Col>
            </Col>
          </Row>
        </Container>
        <hr style={{backgroundColor:'white'}}/>
        <div className="text-center secondary-text">
          © 2023 Juan's Car Wash & Detail | All Rights Reserved
        </div>
      </footer>
    </React.Fragment>
  );
}

export default Footer;

import React from "react";
import { BrowserRouter } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AppRoutes from "./routes/AppRoutes";
import "./App.css";

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <AppRoutes></AppRoutes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;

import React from "react";
import { useNavigate } from "react-router-dom";
import "./errorpage.css";

function ErrorPage() {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-center vh-100 secondary-text">
        <div className="text-center">
          <h1 className="display-1 fw-bold">404</h1>
          <p className="fs-3">
            {" "}
            <span className="text-danger"> Oops!</span>{" "}
          </p>
          <p className="lead"> The page you're looking for doesn't exist.</p>
          <div className="btn btn-primary" onClick={() => navigate("/")}>
            Go Home
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ErrorPage;

import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../components/homepage/HomePage";
import PricingPage from "../components/pricingpage/PricingPage";
import TestimonialsPage from "../components/testimonials/TestimonialsPage";
import ErrorPage from "../components/errorpage/ErrorPage";

export default function AppRoutes() {
  return (
    <Routes>
      <Route index element={<HomePage />} path="/" />
      <Route element={<ErrorPage />} path="*"></Route>
      <Route element={<PricingPage />} path="/pricing" />
      <Route
        element={<TestimonialsPage></TestimonialsPage>}
        path="/testimonials"
      ></Route>
    </Routes>
  );
}

import React from "react";
import Modal from "react-bootstrap/Modal";
import { FaPhone } from "react-icons/fa";
//no need to import css for secondary-font; bootstrap modals use the secondary-font by default. ("Helvetica Neue")

const BookingModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className="closeButton">
        <Modal.Title className="secondary-text">Booking Service</Modal.Title>
      </Modal.Header>
      <Modal.Body className="secondary-text">
        Currently, we're handling all appointment bookings exclusively through
        phone calls. Feel free to reach out to us Monday through Friday, between
        8 a.m. and 5 p.m., to set up a time for your vehicle's cleaning. We're
        excited to get your ride looking sharp!
        <br />
        <br />
        -Only $5 travel fee!!
        <br />
        <br />
        <FaPhone /> <strong>213-732-9769</strong>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary secondary-text"
          onClick={handleClose}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default BookingModal;
